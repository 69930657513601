<template>
    <div class="dashboard-logged mainWrapper bg-dark">
        <section class="blockElement masterPips pb-3">
            <div class="container max-1370">
                <div class="row">
                    <div class="col-12">

                    

                        <h1 class="mb-2 f-24 secondary bold">Frequently Asked Questions</h1>
                        
                        
                                    <div class="faq-entry">
                                        <h3>
                                            What is ZuluTrade?</h3>
                                        <p>
                                            ZuluTrade bridged the gap between valuable information in money markets and
                                            trade execution, by converting the advice of professional and talented
                                            traders globally to an executed trade rapidly and automatically in your
                                            account (from supported brokers)
                                        </p>
                                        <p>
                                            There was a time when trading was a headache. Not anymore! You don't have to
                                            study or monitor the market, because hundreds of Traders from all over the
                                            world are doing it for you. All you have to do is pick the Traders you like,
                                            and ZuluTrade will quickly convert their advice into live trades in your
                                            trading account directly with the broker. ZuluTrade offers <strong>Forex,
                                                CFD and Cryptocurrency</strong> trading. Binary Options trading is no
                                            longer supported!
                                        </p>
                                        <p>
                                            Check the performance results of our Traders <a href="/traders">here</a>
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            How do I get started?
                                        </h3>
                                        <p>
                                            All you have to do is provide ZuluTrade with the name and account number of
                                            the brokerage firm you're trading with. To bridge the experts' advice with
                                            your broker account, you will need to provide consent to your Broker
                                            (normally by agreeing or signing an agreement or Letter of Direction).
                                            Click <a href="signup-live">here</a> to see which brokers we support.
                                        </p>
                                        <p>
                                            If it is the first time that you will trade online or you never had an
                                            on-line trading account with one of the brokers we support, then the first
                                            step is to open a new account. Click <a href="signup-live">here</a> to open
                                            one now in just 5 minutes.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            I already have an account with one of the dealing brokers you support. What
                                            is the next step?
                                        </h3>
                                        <p>
                                            Click <a href="signup-live">here</a> and follow the wizard. When your broker
                                            notifies you that the change has been made, you will receive an email from
                                            us that you are ready to start using ZuluTrade. This process will take a
                                            maximum of 2 days.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            I do not want to open a new account, and I don’t want to sign any forms,
                                            even though there is not any cost involved with it. I prefer to use my
                                            existing account with the broker I’m trading with, without any changes. Is
                                            this possible?</h3>
                                        <p>
                                            No. By signing the RB form, you enable ZuluTrade to receive commissions from
                                            the broker or dealer you have an account with, and most importantly for
                                            ZuluTrade to pay the Traders that are generating the trades being executed
                                            in your account. So if you do not wish to sign the RB form with the existing
                                            account, the alternative is to open a new account with your broker by
                                            signing the same RB form for that new account.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            To link your existing broker account with our revolutionary trading platform
                                        </h3>
                                        <p>
                                            Please click <a href="signup-live">here</a> to fill in the signup form. We
                                            will then automatically send you your login details to begin autotrading
                                            with our platform. Accounts are usually activated within 3-5 days.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            How long does it take to open a new account with my existing broker?</h3>
                                        <p>
                                            It only takes 1 day. Most brokers – if you already had an account with them,
                                            do not require duplicate proof of records, and will provide you with a new
                                            account number. Don’t forget to tell them that your Referring Broker is
                                            ZuluTrade. Click <a href="signup-live">here</a> to open a broker account
                                            already setup with ZuluTrade.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            I don’t have a trading account with the brokers you support. Is that a
                                            problem?</h3>
                                        <p>
                                            No. You can open a new account already set up with ZuluTrade now by clicking
                                            <a href="signup-live">here</a>.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            Can I open my account in different base currency than USD?</h3>
                                        <p>
                                            Yes. You can choose between USD, JPY, EUR, GBP and AUD.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            What is a lot?</h3>
                                        <p>
                                            A standard lot is a buy or sell size. If you buy 1 lot of EUR/USD, trading
                                            at 100:1 leverage then you buy 100,000 EUR/USD. But since you’re trading
                                            with 100:1, the leveraged value is 1,000 EUR/USD or 1 standard lot. If you
                                            trade a mini lot, then you buy 10,000 EUR/USD and the leveraged value is 100
                                            EUR/USD or 1 mini lot.
                                        </p>
                                        <p>
                                            In this case, 0.0001 * 100,000 = 10 and then, 10 / 1.13798 = 8.78750. The
                                            pip value in this case is: €8.79
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            Can I trade manually? Or will it conflict with the automatic trades?</h3>
                                        <p>
                                            Yes. Manual trades will not affect automatic trades.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            What are your trading hours?</h3>
                                        <p>
                                            Trading hours vary by product. For Forex, trading opens on Sundays st 9:00
                                            pm and closes on Fridays around 9:00 pm UTC times.
                                        </p>
                                        <p>
                                            For CFD trading hours, click <a href="/trader-guide?s=cfds">here</a>.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            Who are these Traders? How did you pick them?</h3>
                                        <p>
                                            We do not pick them. Any individual can become a ZuluTrade Trader. It is not
                                            possible on ZuluTrade to misrepresent the performance of the signals
                                            generated. If ZuluTrade shows a profit or loss, it is accurate, because
                                            details are confirmed by the dealing broker’s statements. For each pip
                                            stated in the performance section, there is a trade registered in the
                                            broker’s back-office.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            How are Traders ranked?</h3>
                                        <p>
                                            Forex Traders appear in the Performance Table ranked according to the
                                            ZuluRank algorithm, based on their performance results.
                                        </p>
                                        <p>
                                            For our US Traders page only, the Trader account must be compliant with the
                                            FIFO and No-Hedging market policy set up by NFA.</p>
                                        <p>


                                            If you want to find out more about the way ZuluRank is calculated, please
                                            click <a href="/zuluranking">here</a>!</p>
                                        <p></p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            How do I control the number of lots and the number of positions a Trader
                                            opens on my account?</h3>
                                        <p>
                                            When logged into your account at ZuluTrade, go to your account settings
                                            page. From there select the ‘max open lots’ drop down menu’, this allows you
                                            to set the number of total lots you wish a Trader to have open at any one
                                            time, before he can open any more lots. Also, next to each Trader's name you
                                            have selected, there is a drop down selection called ‘lots’. This pertains
                                            to the number of lots to be traded on each individual trade. For example: if
                                            you wish each Trader to trade with 2 lots per position, but wish there to be
                                            no more than 2 trades
                                            open at a time. Then you would set max open lots to 4, and number of lots
                                            next to your Traders name to 2.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            How do you sign up to be a Trader and/or an affiliate?
                                        </h3>
                                        <p>
                                            You can find links to our <a href="/affiliate-program">Affiliate</a> and <a
                                                href="/trader-program">Trader Programs</a> at the bottom right of every
                                            page, or at the general <a href="/signup-intermediate">Register</a> page.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            Do you offer institutional accounts?
                                        </h3>
                                        <p>
                                            Yes, we also offer various institutional account types based on trading
                                            volume. Please contact us for more details.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            Who is placing the trades on my account? Do the experts know about my
                                            account?</h3>
                                        <p>
                                            None of the Traders that recommend trades will ever know your account's
                                            existence. ZuluTrade receives their recommendations, and checks the Traders
                                            you have subscribed with in your account profile, and decides whether or not
                                            to autotrade their advice in your Live account using a secure direct
                                            connection with the broker's backend. ZuluTrade's patented engine places
                                            trades automatically with no human intervention. ZuluTrade service is server
                                            based, in other words you don't even need to have your computer on.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            Are the results hypothetical? There are a lot of websites that claim all
                                            kinds of results...</h3>
                                        <p>
                                            Every signal received by ZuluTrade is executed on at least one live / demo
                                            broker account. The results contain final spread, swap rates and profit or
                                            loss from closed positions in the account. The spread is the standard spread
                                            advertised by the broker you're trading with. Due to a variety of factors
                                            outlined in the disclaimer found at the bottom of the page, sometimes
                                            results may vary between accounts. All signals and trades executed on a demo
                                            account are considered to be hypothetical.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            I already have an account with one of the dealing brokers you support. Why
                                            is ZuluTrade telling me that I cannot use this account? Signing the RB form
                                            doesn't work. Do I need to open a new account? What’s wrong?</h3>
                                        <p>
                                            This is because when you opened this account, there was a party that acted
                                            as a Referring Broker (RB). Your dealing broker cannot remove that Referring
                                            Broker from your account and replace it with ZuluTrade as the new one (legal
                                            issues). You need to open a new account and set ZuluTrade as your Referring
                                            Broker. Click <a href="signup-live">here</a> to open a new account already
                                            setup for ZuluTrade.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            Why do I need to type my username for the broker platform I’m trading with?
                                            Is there any danger for anybody to steal it?</h3>
                                        <p>
                                            No, there is no danger. We will never ask you for your password. But even If
                                            your password was stolen, it is not possible for anyone (including
                                            ZuluTrade) to withdraw funds from your account. This is because your broker,
                                            who always wires funds to the beneficiary of the account ONLY, is the one
                                            who had opened the account
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            What is a pip?</h3>
                                        <p>
                                            A pip is the minimum movement of a currency upwards or downwards. For forex,
                                            the pip value calculation is performed as follows: <br>
                                            Pip Value = (Pip in decimal places * Trade Size) / Market Price
                                        </p>
                                        <p>
                                            Let's assume you trade 1 standard lot of EUR/USD with an account denominated
                                            in EUR. One pip in decimals for the standard lot = 0.0001, Trade Size =
                                            100,000, Exchange Rate = 1.13798.
                                        </p>
                                        <p>
                                            In this case, 0.0001 * 100,000 = 10 and then, 10 / 1.13798 = 8.78750. The
                                            pip value in this case is: €8.79
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            What is the minimum deposit to open a new account?</h3>
                                        <p>
                                            It depends on the broker, but generally you can start with as little as
                                            $300.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            I logged into my account. How many Traders should I trade with?
                                        </h3>
                                        <p>
                                            When you login to your account, you will be asked to set your Risk Meter Bar
                                            and add traders to your portfolio. Based on the percentage of risk that you
                                            will set at your Risk Meter Bar, trading size will be automatically
                                            allocated for the traders you will add. If the percentage you have set is
                                            not enough for all traders, some of them may have 0 lots allocated. You can
                                            always change the percentage of the Risk Meter Bar, or remove traders, so as
                                            to set your portfolio as per your preference.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            Can you add more Traders?</h3>
                                        <p>
                                            Send an email to <a
                                                href="mailto:support@zulutrade.com">support@zulutrade.com</a> and we
                                            will add them as soon as possible.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            I have opened a new account with my broker. How do I know everything is ok?
                                        </h3>
                                        <p>
                                            Your broker will send you an email confirming that we are the Referring
                                            Broker, and that your account is ready. Then, you log in to ZuluTrade and
                                            configure your trades.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            What is ‘spread’ and why do all my trades start off with negative pips?</h3>
                                        <p>
                                            The spread in the Forex currency trading market, refers to the difference
                                            between the ‘bid’ price for the currency and the ‘ask’ price.
                                            Each traded currency pair opens with the spread. So if the spread is 5, then
                                            your trade will open at –5 pips.
                                        </p>
                                    </div>
                                    <div class="faq-entry">
                                        <h3>
                                            What do Traders and Affiliates do? And how do they receive commission?
                                        </h3>
                                        <p>
                                            A Trader places trades on his or her account, and all clients that have
                                            selected this Trader in their account settings page receive these trades on
                                            their accounts, unless they do not have enough usable margin, or have their
                                            max open lots set to low. Traders provide signals for all clients who select
                                            them in their accounts settings page.<br>
                                            Affiliates choose a designated URL (web page) to advertise ZuluTrade and
                                            draw clients to use our revolutionary service.<br>
                                            Traders get 0.5 pips for each traded lot of his or her own signals ONLY. So
                                            Trader signs up, sends signals, and passively waits for clients to use their
                                            signals, trying to have good performance to attract them.<br>
                                            An Affiliate gets 0.4 pips for each traded lot of the clients that signed up
                                            through his website, for ANY signal. So Affiliate signs up, and starts
                                            advertising their website to attract clients to ZuluTrade.
                                        </p>
                                    </div>
                                

                        
                        

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>